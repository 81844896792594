import React from "react";
import {
  useDesktopMediaQuery,
  Desktop,
  IsMobileContent,
  Mobile,
} from "../../media-queries/MediaQueries";
import Description from "../Global/Description";
import AddProduct from "../Global/AddProduct/AddProduct";
import { Link } from "react-router-dom";
import "./SavedProductsCard.scss";
import Image from "../Global/Image/Image";
import Title from "../Global/Title/Title";
import { HEADING_LEVELS } from "../Global/GlobalConstants";
import { getUrlPath } from "../../helpers/LinkHelper";
import { useGTMDispatch } from "@elgorditosalsero/react-gtm-hook";

function SavedProductsCard({ product, labels, productImageHeight, productImageWidth }) {
  const sendDataToGTM = useGTMDispatch();
  const {
    mlSourceReduced = "",
    mlCommerciallyCompostable = "",
    mlRecycledContentLabel = "",
    mlRenewableResourcesLabel = "",
    addYourBrandLabel = "",
    newProductLabel = "",
    viewDetailsLabel = "",
    sustainableFeaturesLabel = "",
    useForLabel = "",
    moreColorsPrintsAvailableLabel = "",
    productMadeInTheUSALabel = "",
    recyclableLabel = "",
    sfiCertifiedLabel = "",
  } = window.globalProperties;
  const mobileMaxSize = 140;

  // Getting Sustainable Features
  const sustainableFeaturesOutput = () => {
    const sustainableFeatures = [
      product["isMadeInUSA"] ? productMadeInTheUSALabel : null,
      product["isRecyclable"] ? recyclableLabel : null,
      product["isRecycledContent"] ? mlRecycledContentLabel : null,
      product["isCompostable"] ? mlCommerciallyCompostable : null,
      product["isRenewableContent"] ? mlRenewableResourcesLabel : null,
      product["isSFICertified"] ? sfiCertifiedLabel : null,
      product["isSourceReduced"] ? mlSourceReduced : null,
    ];

    return sustainableFeatures
      .filter((item) => item !== null)
      .map((item, index) => {
        return (
          <li key={index} className="saved-products-card__feature">
            <span className="saved-products-card__checkmark-icon darticon-checkmark"></span>
            {item}
          </li>
        );
      });
  };

  const isDesktop = useDesktopMediaQuery();

  let imageClass;
  if (!productImageWidth && isDesktop) {
    imageClass = "img-desktop";
  } else if (!productImageWidth && !isDesktop) {
    imageClass = "img-mobile";
  } else {
    imageClass = "custom";
  }

  const isActiveProduct = product["publishStatus"]?.toString().toLowerCase() !== "inactive";

  const handleDetailClick = () => {
    sendDataToGTM({
      event: "viewDetails",
      productName: product["childProductName"],
      productID: product.productCode,
    });
  };

  return (
    <div className="saved-products-card">
      {/* Product Cards Wrapper */}
      <div className="saved-products-card__wrapper">
        {/* Product Card */}
        <div className="saved-products-card__card-outer">
          {/* Add Product CTA */}
          <div className="saved-products-card__add-product-cta">
            <AddProduct
              discontinuedProduct={!isActiveProduct}
              productCode={product.productCode}
              productName={product["childProductName"]}
              btnSize={IsMobileContent() ? "small" : "medium"}
            />
          </div>

          {/* New & Add Your Brand Banners */}
          {(product["isPrintable"] || product["isEmbossable"] || product["isNewProduct"]) && (
            <div className="saved-products-card__banners">
              {product["isNewProduct"] && (
                <p className="saved-products-card__banner primary">{newProductLabel}</p>
              )}
              {(product["isPrintable"] || product["isEmbossable"]) && (
                <p className="saved-products-card__banner secondary">{addYourBrandLabel}</p>
              )}
            </div>
          )}

          <div className="saved-products-card__card-inner">
            {/* Image */}
            <div
              className={`saved-products-card__product-image-wrapper saved-products-card__product-image-wrapper-${imageClass}`}
            >
              {product["catalogImage"] && (
                <>
                  <Desktop>
                    <Image
                      className={`saved-products-card__product-image saved-products-card__product-image-${imageClass}`}
                      src={product["catalogImage"]}
                      alt={product["catalogImageAlternateText"]}
                      width={productImageWidth}
                      height={productImageHeight}
                    />
                  </Desktop>
                  <Mobile>
                    <Image
                      className={`saved-products-card__product-image saved-products-card__product-image-${imageClass}`}
                      src={product["catalogImage"]}
                      alt={product["catalogImageAlternateText"]}
                      width={productImageWidth === 0 ? mobileMaxSize : productImageWidth}
                      height={productImageHeight === 0 ? mobileMaxSize : productImageHeight}
                    />
                  </Mobile>
                </>
              )}

              {/* Discontinued Product Banner */}
              {!isActiveProduct && (
                <p className="saved-products-card__product-discontinued">
                  {labels["discontinuedLabel"]}
                </p>
              )}

              {/* View Details CTA */}
              {product["url"] && isActiveProduct && (
                <Mobile>
                  <Link
                    className="btn saved-products-card__view-details-cta"
                    onClick={handleDetailClick}
                    to={getUrlPath(product.url)}
                  >
                    {viewDetailsLabel}
                  </Link>
                </Mobile>
              )}
            </div>

            {/* Product Info */}
            <div className="saved-products-card__product-info-wrapper">
              {product["childProductName"] && (
                <Title
                  defaultLevel={HEADING_LEVELS.FIVE}
                  className="saved-products-card__product-name"
                >
                  {product["childProductName"]}
                </Title>
              )}

              {product["productCode"] && (
                <p className="saved-products-card__product-text sku-info">
                  {product["productCode"] && <span>#{product["productCode"]}</span>}
                  {product.variations && product.variations.length > 0 ? 
                    <Desktop>
                      <span className="saved-products-card__vertical-separator">|</span>
                      <span>{moreColorsPrintsAvailableLabel}</span>
                    </Desktop>
                    : null}
                </p>
              )}

              {product["temperatureUsageCatalogFilter"]?.length > 0 && (
                <p className="saved-products-card__product-text description-count">
                  <span>{useForLabel}: </span>
                  <span>
                    {Array.isArray(product["temperatureUsageCatalogFilter"])
                      ? product["temperatureUsageCatalogFilter"].join(", ")
                      : product["temperatureUsageCatalogFilter"]}
                  </span>
                </p>
              )}

              {/* Item Count */}
              {product["effectiveUnitsPerCase"] && (
                <p className="saved-products-card__product-text description-count">
                  <span>{labels["itemCountLabel"]}: </span>
                  <span>{product["effectiveUnitsPerCase"]}</span>
                </p>
              )}

              {/* Discontinued Message */}
              {!isActiveProduct && (
                <Desktop>
                  <Description
                    className="saved-products-card__product-text alert"
                    value={labels["discontinuedDescription"]}
                  />
                </Desktop>
              )}
            </div>

            {/* Product Features */}
            <Desktop>
              <div className="saved-products-card__product-features-wrapper">
                {sustainableFeaturesOutput().length > 0 && (
                  <>
                    <b className="saved-products-card__features-label">
                      {sustainableFeaturesLabel}:
                    </b>
                    <ul className="saved-products-card__features-list">
                      {sustainableFeaturesOutput()}
                    </ul>
                  </>
                )}

                {/* View Details CTA */}
                {product["url"] && isActiveProduct && (
                  <Link
                    className="btn saved-products-card__view-details-cta"
                    onClick={handleDetailClick}
                    to={getUrlPath(product.url)}
                  >
                    {viewDetailsLabel}
                  </Link>
                )}
              </div>
            </Desktop>
          </div>

          {/* Availability Alert Message */}
          {!isActiveProduct && (
            <Mobile>
              <Description
                className="saved-products-card__product-text alert"
                value={labels["discontinuedDescription"]}
              />
            </Mobile>
          )}
        </div>
      </div>
    </div>
  );
}

export default SavedProductsCard;
