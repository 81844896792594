import React, { useState } from "react";
import { BrPageContext } from "@bloomreach/react-sdk";
import { Desktop, Mobile, IsMobileContent } from "../../media-queries/MediaQueries";
import Description from "../Global/Description";
import AddProduct from "../Global/AddProduct/AddProduct";
import "./ProductAndProductFamilyCard.scss";
import { getPageTitle } from "../../helpers/GlobalHelpers";
import Image from "../Global/Image/Image";
import Title from "../Global/Title/Title";
import { HEADING_LEVELS } from "../Global/GlobalConstants";
import CtaLink from "../Global/CtaLink";

function ProductAndProductFamilyCard({ product = {}, productImageHeight, productImageWidth }) {
  const NUMFEATURESDISPLAYED = 3;
  const [viewMorePressed, setViewMorePressed] = useState(false);
  const [keyFeaturesCount, setKeyFeaturesCount] = useState(NUMFEATURESDISPLAYED);
  const page = React.useContext(BrPageContext);
  const defaultDesktopProductSize = 260;
  const defaultMobileProductSize = 140;
  // Used as a Product component when object passed in has values for Add Brand / New badges, Sku, Info, Description, Key and Sustainable Features

  // Used as a Product Family component when object passed in has values for Product Collection Banner, Promo Description, Product Features

  // Global Properties
  const {
    mlSourceReduced = "",
    mlCommerciallyCompostable = "",
    mlRecycledContentLabel = "",
    mlRenewableResourcesLabel = "",
    mlViewAllProducts = "",
    viewDetailsLabel = "",
    sustainableFeaturesLabel = "",
    productFeaturesLabel = "",
    viewMoreLabel = "",
    viewLessLabel = "view less",
    keyFeaturesLabel = "",
    moreColorsPrintsAvailableLabel = "",
    productCollectionLabel = "",
    addYourBrandLabel = "",
    newProductLabel = "",
    productMadeInTheUSALabel = "",
    recyclableLabel = "",
    sfiCertifiedLabel = "",
    proPlanetSustainableLabel = "",
    designedForRecyclingLabel = "",
    topRackDishwasherSafeLabel = "",
  } = window.globalProperties;

  // Checking whether item is a Product or Product Family
  const isProduct = product.contentType === "product" ? true : false;
  const isProductFamily = product.contentType === "productFamily" ? true : false;

  // Checks if product is new, can be printable, is pro-planet, or is designed for recycling
  // "isPrintable", "isEmbossable", "isNewProduct", "isProPlanet" and "isDesignedForRecycling" return true or false
  const isPrintable = product["isPrintable"] ? addYourBrandLabel : null;
  const isEmbossable = product["isEmbossable"] ? addYourBrandLabel : null;
  const isNew = product["isNewProduct"] ? newProductLabel : null;
  const isProPlanet = product["isProPlanet"];
  const isCompostable = product["isCompostable"];
  const isDesignedForRecycling = product["isDesignedForRecycling"];

  // Setting product details based on content type
  const imageUrl = isProduct ? product["catalogImage"] : product.promoImages[0];
  const productName = isProduct ? product["childProductName"] : product.promoTitle;

  const productSku = product["productCode"] || null;
  const productDescription = isProduct ? product["productDescription"] : product.promoDescription;

  const isMobile = IsMobileContent();

  // Handles Navigation Click
  // If product, will navigate to PDP, else will navigate to PLP
  const handleViewProductsOrDetailClick = () => {
    if (isProduct) {
      return "_self"
    } else if (isProductFamily) {
      return "_blank"
    }
  };

  // Getting Key Features
  const keyFeaturesOutput = () => {
    let keyFeatures = [];
    if (product.specialFeaturesCatalogFilter) {
      keyFeatures = product.specialFeaturesCatalogFilter;
    }
    // if only one feature, it is sometimes getting set to a string. Change to a single item array.
    if (typeof keyFeatures === "string") keyFeatures = [keyFeatures].flat();

    return keyFeatures
      .filter((item) => item !== null)
      .map((item, index) => {
        return (
          <li key={index} className="product-and-product-family__key-feature">
            {item}
          </li>
        );
      });
  };

  // Handles "View More or View Less"
  // Loads remaining Key Features
  const handleViewMore = () => {
    if (viewMorePressed === false) {
      setKeyFeaturesCount(keyFeaturesOutput().length);
      setViewMorePressed(true);
    } else if (viewMorePressed === true) {
      setKeyFeaturesCount(NUMFEATURESDISPLAYED);
      setViewMorePressed(false);
    }
  };

  // Getting Material Icons
  const materialIconsOutput = () => {
    const materialIcons =
      product.materialIcons && product.materialIcons.length > 0 ? product.materialIcons : [];

    return materialIcons
      .filter((item) => item !== null)
      .map((item, index) => {
        return (
          <li key={index} className="product-and-product-family__material-icons-list-item">
            <span className={`product-and-product-family__material-icon dart${item}`} aria-label={item} title={item} role="img"></span>
          </li>
        );
      });
  };

  // Getting Product Features
  const productFeaturesOutput = () => {
    const productFeatures =
      product.productFeatures && product.productFeatures.length > 0 ? product.productFeatures : [];

    return productFeatures
      .filter((item) => item !== null && item !== "")
      .map((item, index) => {
        return (
          <li key={index} className="product-and-product-family__product-sustainable-feature">
            <span className="product-and-product-family__button-icon darticon-checkmark checkmark"></span>
            {item}
          </li>
        );
      });
  };
  // Getting Sustainable Features
  const sustainableFeaturesOutput = () => {
    const sustainableFeatures = [
      product["isProPlanet"] ? proPlanetSustainableLabel : null,
      product["isDesignedForRecycling"] ? designedForRecyclingLabel : null,
      product["isMadeInUSA"] ? productMadeInTheUSALabel : null,
      product["isRecyclable"] ? recyclableLabel : null,
      product["isRecycledContent"] ? mlRecycledContentLabel : null,
      product["isCompostable"] ? mlCommerciallyCompostable : null,
      product["isRenewableContent"] ? mlRenewableResourcesLabel : null,
      product["isSFICertified"] ? sfiCertifiedLabel : null,
      product["isSourceReduced"] ? mlSourceReduced : null,
      product["isTopRackDishwasherSafe"] ? topRackDishwasherSafeLabel : null,
    ];

    return sustainableFeatures
      .filter((item) => item !== null && item !== "")
      .map((item, index) => {
        let iconClass = item == proPlanetSustainableLabel ? `darticon-proPlanet_Leaf ${!product["isCompostable"] && "blue"}` : "darticon-checkmark checkmark";
        return (
          <li key={index} className='product-and-product-family__product-sustainable-feature'>
            <span className={`product-and-product-family__button-icon ${iconClass}`}></span>
            {item}
          </li>
        );
      });
  };

  const listOutput = isProduct ? sustainableFeaturesOutput() : productFeaturesOutput();

  return (
    <div className="product-and-product-family">
      <div className="product-and-product-family__wrapper">
        {/* Add Button */}
        {isProduct && product["productCode"] && (
          <div className="product-and-product-family__button">
            <AddProduct
              productCode={product["productCode"]}
              productName={productName}
              btnSize={isMobile ? "small" : "medium"}
              addToListAnalytics={{
                event: "listAdd",
                listType: "Product Listing Page",
                productName: `${getPageTitle(page)}`,
              }}
              removeFromListAnalytics={{
                event: "removeFromList",
                listType: "Product Listing Page",
                productName: `${getPageTitle(page)}`,
              }}
            />
          </div>
        )}

        <div className="product-and-product-family__product">
          {/* New & Add Your Brand Banners */}
          {isProduct && (isPrintable || isEmbossable || isNew || isProPlanet) && (
            <div className="product-and-product-family__banners">
              {/* "New!" */}
              {isNew && (
                <p className="product-and-product-family__banner primary">
                  {window.globalProperties["newProductLabel"] || "New!"}
                </p>
              )}
              {/* "ProPlanet" */}
              {isProPlanet && (
                <p className="product-and-product-family__banner icon" aria-label="pro planet">
                  {isCompostable ? (
                    <img src="https://dart.widen.net/content/vsjmstuwtr/svg/ProPlanet-Leaf-CheckmarkwStroke-Green.svg?u=hsm95h" />
                  ):(
                    <img src="https://dart.widen.net/content/haqggj6fsh/svg/ProPlanet-Leaf-CheckmarkwStroke-Blue.svg?u=xd7jz4" />
                  )}
                </p>
              )}
              {/* "Add Your Brand" */}
              {(isPrintable || isEmbossable) && (
                <p className="product-and-product-family__banner secondary bottom">
                  {window.globalProperties["addYourBrandLabel"] || "Add Your Brand"}
                </p>
              )}
            </div>
          )}
          {/* Image, Product Collection & CTA Section */}
          <div className="product-and-product-family__product-image-container">
            {/* Product Image */}
            {imageUrl && imageUrl.length > 0 && (
              <div className="product-and-product-family__product-image-wrapper">
                <Image
                  className="product-and-product-family__product-image"
                  src={imageUrl}
                  alt={productName}
                  width={
                    productImageWidth
                      ? productImageWidth
                      : isMobile
                      ? defaultMobileProductSize
                      : defaultDesktopProductSize
                  }
                  height={
                    productImageHeight
                      ? productImageHeight
                      : isMobile
                      ? defaultMobileProductSize
                      : defaultDesktopProductSize
                  }
                />
                {/* Product Collection Banner */}
                {isProductFamily && (
                  <p className="product-and-product-family__product-collection">
                    {productCollectionLabel}
                  </p>
                )}
              </div>
            )}
            {/* View All Products || View Details CTA */}
            {product.url && (
              <Mobile>
              <a
                className="product-and-product-family__view-details-cta btn" 
                aria-label={isProduct ? viewDetailsLabel + ' ' + productName : mlViewAllProducts}
                href={product.url}
                target={handleViewProductsOrDetailClick()} >
                {isProduct ? viewDetailsLabel : mlViewAllProducts}
              </a>     
              </Mobile>
            )}
            {product.promoLink &&
              (product.promoLink.internalLink || product.promoLink.externalLink) && (
                <Mobile>
                  <CtaLink
                    className="product-and-product-family__view-details-cta btn"
                    link={product.promoLink}
                  />
                </Mobile>
              )}
          </div>

          {/* Product Information Section */}
          <div className="product-and-product-family__product-info">
            {/* Product Name */}
            {productName && (
              <Title
                defaultLevel={HEADING_LEVELS.TWO}
                className="product-and-product-family__product-name"
              >
                {productName}
              </Title>
            )}
            {/* Product Sku & Info */}
            {isProduct && productSku && (
              <p className="product-and-product-family__product-body sku">
                #{productSku}
                {product.variations && product.variations.length > 0 ? <Desktop> | {moreColorsPrintsAvailableLabel}</Desktop> : null}
              </p>
            )}
            {/* Product Description */}
            {productDescription && productDescription.length > 0 && (
              <Description
                className={`product-and-product-family__product-body ${
                  isProduct ? "desc" : "promo-desc"
                }`}
                value={productDescription}
              />
            )}
            {/* Product Info */}
            {isProduct && product.variations && product.variations.length > 0 && (
              <Mobile>
                <p className="product-and-product-family__product-body info">
                  {moreColorsPrintsAvailableLabel}
                </p>
              </Mobile>
            )}

            {/* Key Features List */}
            {isProduct && keyFeaturesOutput().length > 0 && (
              <Desktop>
                {/* List Header */}
                <p className="product-and-product-family__product-body feature-label">
                  {keyFeaturesLabel}
                </p>
                {/* Key Features List */}
                <ul className="product-and-product-family__key-features">
                  {keyFeaturesOutput().slice(0, keyFeaturesCount)}
                </ul>
                {/* View More / View Less CTA */}
                {keyFeaturesOutput().length > 3 && (
                  <button
                    className="product-and-product-family__view-more-less-cta"
                    onClick={handleViewMore}
                    type="button"
                    label={`${viewMorePressed ? viewLessLabel : viewMoreLabel} ${productSku}`}
                    aria-label={`${viewMorePressed ? viewLessLabel : viewMoreLabel} ${productSku}`}
                  >
                    <p className="product-and-product-family__view-more-less-text">
                      {viewMorePressed ? viewLessLabel : viewMoreLabel}
                    </p>
                    <span
                      className={`product-and-product-family__button-icon ${
                        viewMorePressed ? "darticon-arrow-up" : "darticon-arrow-down"
                      } down-icon`}
                    ></span>
                  </button>
                )}
              </Desktop>
            )}
            {/* Material Icons */}
            {isProductFamily && materialIconsOutput().length >= 1 && (
              <div className="product-and-product-family__material-icons-container">
                <ul className="product-and-product-family__material-icon-list">
                  {materialIconsOutput()}
                </ul>
              </div>
            )}
          </div>

          {/* Product & Sustainable Features Section */}
          <Desktop>
            <div className="product-and-product-family__product-sustainable-container">
              {/* List Header */}
              {listOutput.length > 0 && (
                <p className="product-and-product-family__product-body feature-label">
                  {isProduct ? sustainableFeaturesLabel : productFeaturesLabel}:
                </p>
              )}
              {/* List Output */}
              {listOutput.length > 0 && (
                <ul className="product-and-product-family__product-sustainable-features">
                  {listOutput}
                </ul>
              )}
              {/* View All Products || View Details CTA */}
              {product.url && product.url.length > 0 && (
                <a
                  className="product-and-product-family__view-details-cta btn" 
                  aria-label={isProduct ? viewDetailsLabel+ ' ' + productName : mlViewAllProducts}
                  href={product.url}
                  target={handleViewProductsOrDetailClick()} >
                  {isProduct ? viewDetailsLabel : mlViewAllProducts}
                </a>                
              )}
              {product.promoLink &&
                (product.promoLink.internalLink || product.promoLink.externalLink) && (
                  <CtaLink
                    className="product-and-product-family__view-details-cta btn"
                    link={product.promoLink}
                  />
                )}
            </div>
          </Desktop>
        </div>
      </div>
    </div>
  );
}

export default ProductAndProductFamilyCard;
